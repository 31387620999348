<template>
  <div class="min-h-full">
    <main class="py-10">
      <div
        class="
          mt-8
          max-w-3xl
          mx-auto
          grid grid-cols-1
          gap-6
          sm:px-6
          lg:max-w-7xl lg:grid-flow-col-dense
        "
      >
        <div class="space-y-6 lg:col-start-1">
          <!-- Description list-->
          <section aria-labelledby="roast schedule">
            <div
              class="
                mt-8
                max-w-3xl
                mx-auto
                grid grid-cols-1
                gap-6
                sm:px-6
                lg:max-w-7xl lg:grid-flow-col-dense
              "
            >
              <div class="space-y-6 lg:col-start-1">
                <!-- Description list-->
                <div>
                  <p>
                    <span class="text-gray-500">
                      This is your schedule for the days when we are roasting
                      this coffee. You can switch a non-roast day on by clicking
                      the greyed out "ship" button. Or, you can add an
                      additional shipment on any day by clicking the plus icon.
                    </span>
                  </p>

                  <h2 class="text-1xl font-bold text-gray-900">
                    {{ props.subscription }}
                  </h2>
                </div>

                <TransitionGroup
                  appear
                  mode="out-in"
                  enter-active-class="duration-300 ease-out"
                  enter-from-class="transform opacity-0 scale-75"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="duration-200 ease-in"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-75"
                >
                  <ItemFulfillmentDay
                    v-for="sfd in sfds"
                    :key="sfd.id"
                    :sfd="sfd"
                    :item="props.item"
                    @addSFD="addSfdToSchedule"
                    @removeSFD="removeSfdFromSchedule"
                    @updateSFD="updateSchedule($event)"
                  />
                </TransitionGroup>
                <!-- Comments-->
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ItemFulfillmentDay from "./ItemFulfillmentDay.vue";
import { inject, reactive } from "vue";
import { showErrorMessage } from "../utils/errorProcessing.js";
import { PlusSmIcon as PlusSmIconSolid } from "@heroicons/vue/solid";

export default {
  components: {
    ItemFulfillmentDay,
    PlusSmIconSolid,
  },
  props: { sfds: Object, subscription: String, id: String, item: Object },
  setup(props) {
    //console.log("in Schedule props are ", JSON.stringify(props));
    const sfds = reactive({ ...props.sfds });
    const item_id = props.item.url; //really item_url not item_id!!

    const axios = inject("axios"); // inject axios

    const removeSfdFromSchedule = (sfd) => {
      //console.log("the value is ", JSON.stringify(sfd));
      axios
        .put(sfd.removeSfdUrl, { item_id, item_page: true })
        .then((result) => {
          //console.log("removed the result is ", result);
          Object.assign(sfds, result.data.sfds);
          for (let key in sfds) if (!result.data.sfds[key]) delete sfds[key];
        })
        .catch((err) => showErrorMessage(err));
    };

    const updateSchedule = (val) => {
      //console.log("the value in is ", val);
      Object.assign(sfds, val.sfds);
    };

    const addSfdToSchedule = (sfd) => {
      //console.log("the value is ", JSON.stringify(sfd));
      //console.log("The sfds when we start are ", sfds);
      //console.log("The sfds when we start are (in value form) ", sfds.value);
      axios
        .put(sfd.addSfdUrl, { item_id, item_page: true })
        .then((result) => {
          //console.log("added the sfd result is ", result);

          Object.assign(sfds, result.data.sfds);
          //console.log("now we've set the value to be ", sfds.value);
        })
        .catch((err) => showErrorMessage(err));
    };

    return {
      sfds,
      props,
      addSfdToSchedule,
      removeSfdFromSchedule,
      updateSchedule,
      PlusSmIconSolid,
    };
  },
};
</script>
