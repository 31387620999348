import axios from "axios";
import VueAxios from "vue-axios";

import { createApp } from "vue";
import Schedule from "./components/Schedule.vue";
export default () => {
  document.addEventListener("DOMContentLoaded", () => {
    Array.prototype.forEach.call(
      document.getElementsByClassName("vue-schedule"),
      (element) => {
        //console.log("element is " + element);
        //console.log("element id is " + element.id);
        //console.log("but the stringify of it is " + JSON.stringify(element));

        //console.log("Vue Schedule Element is " + JSON.stringify(element));
        if (element) {
          const props = JSON.parse(element.getAttribute("data"));
          if (props) {
            //console.log("props is " + JSON.stringify(props));

            const app = createApp(Schedule, props);
            let token = document
              .getElementsByName("csrf-token")[0]
              .getAttribute("content");
            axios.defaults.headers.common["X-CSRF-Token"] = token;
            axios.defaults.headers.common["Accept"] = "application/json";
            app.use(VueAxios, axios);
            app.provide("axios", app.config.globalProperties.axios); // provide 'axios'
            app.mount("#" + element.id);
            //console.log("mounted schedule");
          } else {
            //console.log("Error no props for schedule");
          }
        }
      }
    );
  });
};
