import initCarousel from "../assets/javascripts/src/carousel.js";
import initGiftSelect from "../assets/javascripts/src/gift_select.js";
import initPlanSelect from "../assets/javascripts/src/plan_select.js";

import initSchedule from "../assets/javascripts/src/schedule.js";
import initItemSchedule from "../assets/javascripts/src/item_schedule.js";

import initShipment from "../assets/javascripts/src/shipment_select.js";

import initNav from "../assets/javascripts/src/top_nav.js";

import starRating from "../assets/javascripts/src/star_rating.js";

 //import itemDisplay from "../assets/javascripts/src/item_display.js";

Promise.all([
  initCarousel(),

  initGiftSelect(),
  initPlanSelect(),
  initSchedule(),
  initShipment(),
  initNav(),
  starRating(),
  initItemSchedule(),
  // itemDisplay(),
]);
