import { createApp } from "vue";
import PlanSelect from "./components/PlanSelect.vue";
export default () => {
  document.addEventListener("DOMContentLoaded", () => {
    Array.prototype.forEach.call(
      document.getElementsByClassName("vue-plan-select"),
      (element) => {
        //console.log("Element is " + element);
        if (element) {
          const app = createApp(PlanSelect);
          app.mount("#" + element.id);
          //console.log("mounted plan-select");
        }
      }
    );
  });
};
