import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import GiftSelect from "./components/GiftSelect.vue";
export default () => {
  document.addEventListener("DOMContentLoaded", () => {
    let element = document.getElementById("vue-gift-select");

    if (element) {
      const props = JSON.parse(element.getAttribute("data"));
      //console.log("props is " + JSON.stringify(props));

      const app = createApp(GiftSelect, props);
      let token = document
        .getElementsByName("csrf-token")[0]
        .getAttribute("content");
      axios.defaults.headers.common["X-CSRF-Token"] = token;
      axios.defaults.headers.common["Accept"] = "application/json";
      app.use(VueAxios, axios);
      app.provide("axios", app.config.globalProperties.axios); // provide 'axios'
      app.mount("#" + element.id);
      //console.log("mounted gift-select");
    } else {
      //console.log("No element found for gift select");
    }
  });
};
