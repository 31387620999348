import { createApp } from "vue";
import Nav from "./components/TopNav.vue";
export default () => {
  document.addEventListener("DOMContentLoaded", () => {
    var element = document.getElementById("vue-top-nav");
    if (element) {
      const props = JSON.parse(element.getAttribute("data"));
      // //console.log("props is " + JSON.stringify(props));
      if (props) {
        const app = createApp(Nav, props);
        app.mount("#vue-top-nav");
      }
    }
  });
};
