
<script>
import { ref } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { CheckCircleIcon } from "@heroicons/vue/solid";

export default {
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    CheckCircleIcon,
  },
  props: { options: Object, label: String, selectedOption: String },

  setup(props) {
    const selectedOption = ref(
      props.options.find((op) => {
        if (op.id == props.selectedOption) {
          return op;
        }
      })
    );

    return {
      selectedOption,

      props,
    };
  },
};
</script>

<template>
  <RadioGroup
    id="props.name"
    v-model="selectedOption"
    @update:modelValue="$emit('optionSelected', $event)"
  >
    <RadioGroupLabel class="text-base font-medium text-gray-900">
      {{ props.label }}
    </RadioGroupLabel>

    <div
      :class="[
        'sm:grid-cols-' + props.options.length,
        ' mt-4 grid grid-cols-1 gap-y-6   sm:gap-x-4',
      ]"
    >
      <RadioGroupOption
        v-for="option in props.options"
        :key="option.id"
        v-slot="{ checked, active }"
        as="template"
        :value="option"
      >
        <div
          :class="[
            checked ? 'border-transparent' : 'border-gray-300',
            active ? 'border-violet-500 ring-2 ring-violet-500' : '',
            'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none',
          ]"
        >
          <div class="flex-1 flex">
            <div class="flex flex-col">
              <RadioGroupLabel
                as="span"
                class="block text-lg font-medium text-gray-900"
              >
                {{ option.title }}
              </RadioGroupLabel>
            </div>
          </div>
          <CheckCircleIcon
            :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-violet-600']"
            aria-hidden="true"
          />
          <div
            :class="[
              active ? 'border' : 'border-2',
              checked ? 'border-violet-500' : 'border-transparent',
              'absolute -inset-px rounded-lg pointer-events-none',
            ]"
            aria-hidden="true"
          />
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>
