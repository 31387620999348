<template>
  <div class="bg-white">
    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="open">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 lg:hidden"
        @close="open = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="
              relative
              max-w-xs
              w-full
              bg-white
              shadow-xl
              pb-12
              flex flex-col
              overflow-y-auto
            "
          >
            <div class="px-4 pt-5 pb-2 flex">
              <button
                type="button"
                class="
                  -m-2
                  p-2
                  rounded-md
                  inline-flex
                  items-center
                  justify-center
                  text-gray-400
                "
                @click="open = false"
              >
                <span class="sr-only">Close menu</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <!-- Links -->
            <div class="border-gray-200 px-4">
              <div class="flow-root">
                <a
                  v-if="!user"
                  href="/users/sign_in"
                  class="p-2 block font-medium text-gray-900"
                  >Sign in</a
                >
                <a
                  v-else
                  href="/users/sign_out"
                  class="p-2 block font-medium text-gray-900"
                  >Sign Out</a
                >

                <div
                  class="
                    flex flex-col flex-grow
                    border-gray-200
                    pt-5
                    pb-4
                    bg-white
                    overflow-y-auto
                  "
                >
                  <div class="flex flex-grow">
                    <div class="flex-grow flex flex-col">
                      <nav
                        aria-label="Sidebar"
                        class="flex-1 bg-white space-y-1"
                      >
                        <div
                          class="
                            border-t border-b border-gray-200
                            py-6
                            px-4
                            space-y-6
                          "
                        >
                          <div
                            v-for="page in props.pages"
                            :key="page.name"
                            class="flow-root"
                          >
                            <a
                              :href="page.href"
                              class="-m-2 p-2 block font-medium text-gray-900"
                              >{{ page.name }}</a
                            >
                          </div>
                        </div>
                        <div v-if="user">
                          <div
                            class="
                              border-l-4
                              group
                              flex
                              items-center
                              px-3
                              py-2
                              text-sm
                              font-medium
                              border-transparent
                              text-gray-600
                              hover:bg-gray-50 hover:text-gray-900
                            "
                          >
                            Account
                          </div>
                          <a
                            class="
                              border-l-4
                              group
                              flex
                              items-center
                              px-3
                              py-2
                              text-sm
                              font-medium
                              border-transparent
                              text-gray-600
                              hover:bg-gray-50 hover:text-gray-900
                            "
                            href="/account"
                          >
                            <svg
                              class="mr-3 flex-shrink-0 h-6 w-6"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              viewbox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Credits
                          </a>
                          <a
                            class="
                              border-l-4
                              group
                              flex
                              items-center
                              px-3
                              py-2
                              text-sm
                              font-medium
                              border-transparent
                              text-gray-600
                              hover:bg-gray-50 hover:text-gray-900
                            "
                            href="/accounts/schedule"
                          >
                            <svg
                              class="mr-3 flex-shrink-0 h-6 w-6"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              viewbox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Schedule
                          </a>
                          <a
                            class="
                              border-l-4
                              group
                              flex
                              items-center
                              px-3
                              py-2
                              text-sm
                              font-medium
                              border-transparent
                              text-gray-600
                              hover:bg-gray-50 hover:text-gray-900
                            "
                            href="/accounts/information"
                          >
                            <svg
                              class="mr-3 flex-shrink-0 h-6 w-6"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              viewbox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Personal
                          </a>
                          <a
                            class="
                              border-l-4
                              group
                              flex
                              items-center
                              px-3
                              py-2
                              text-sm
                              font-medium
                              border-transparent
                              text-gray-600
                              hover:bg-gray-50 hover:text-gray-900
                            "
                            href="/preferences"
                          >
                            <svg
                              aria-hidden="true"
                              class="mr-3 flex-shrink-0 h-6 w-6"
                              fill="none"
                              stroke="currentColor"
                              viewbox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                            </svg>
                            Preferences
                          </a>
                          <a
                            class="
                              border-l-4
                              group
                              flex
                              items-center
                              px-3
                              py-2
                              text-sm
                              font-medium
                              border-transparent
                              text-gray-600
                              hover:bg-gray-50 hover:text-gray-900
                            "
                            href="/accounts/subscriptions"
                          >
                            <svg
                              aria-hidden="true"
                              class="mr-3 flex-shrink-0 h-6 w-6"
                              fill="none"
                              stroke="currentColor"
                              viewbox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                            </svg>
                            Subscriptions
                          </a>
                          <a
                            class="
                              border-l-4
                              group
                              flex
                              items-center
                              px-3
                              py-2
                              text-sm
                              font-medium
                              border-transparent
                              text-gray-600
                              hover:bg-gray-50 hover:text-gray-900
                            "
                            href="/accounts/shipments"
                          >
                            <svg
                              class="mr-3 flex-shrink-0 h-6 w-6"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              viewbox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
                              />
                              <path
                                d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Shipments
                          </a>
                          <a
                            class="
                              border-l-4
                              group
                              flex
                              items-center
                              px-3
                              py-2
                              text-sm
                              font-medium
                              border-transparent
                              text-gray-600
                              hover:bg-gray-50 hover:text-gray-900
                            "
                            href="/accounts/payments"
                          >
                            <svg
                              class="mr-3 flex-shrink-0 h-6 w-6"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              viewbox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Payments
                          </a>
                          <a
                            class="
                              border-l-4
                              group
                              flex
                              items-center
                              px-3
                              py-2
                              text-sm
                              font-medium
                              border-transparent
                              text-gray-600
                              hover:bg-gray-50 hover:text-gray-900
                            "
                            href="/accounts/gifts"
                          >
                            <svg
                              class="mr-3 flex-shrink-0 h-6 w-6"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              viewbox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Gifts
                          </a>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <TabGroup as="div" class="mt-2">
              <div class="border-b border-gray-200">
                <TabList class="-mb-px flex px-4 space-x-8">
                  <Tab
                    v-for="category in props.categories"
                    :key="category.name"
                    v-slot="{ selected }"
                    as="template"
                  >
                    <button
                      :class="[
                        selected
                          ? 'text-violet-600 border-violet-600'
                          : 'text-gray-900 border-transparent',
                        'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium',
                      ]"
                    >
                      {{ category.name }}
                    </button>
                  </Tab>
                </TabList>
              </div>
              <TabPanels as="template">
                <TabPanel
                  v-for="(category, categoryIdx) in props.categories"
                  :key="category.name"
                  class="px-4 pt-10 pb-6 space-y-12"
                >
                  <div class="grid grid-cols-1 items-start gap-y-10 gap-x-6">
                    <div class="grid grid-cols-1 gap-y-10 gap-x-6">
                      <div>
                        <p
                          :id="`mobile-featured-heading-${categoryIdx}`"
                          class="font-medium text-gray-900"
                        >
                          Featured
                        </p>
                        <ul
                          role="list"
                          :aria-labelledby="`mobile-featured-heading-${categoryIdx}`"
                          class="mt-6 space-y-6"
                        >
                          <li
                            v-for="item in category.featured"
                            :key="item.name"
                            class="flex"
                          >
                            <a :href="item.href" class="text-gray-500">
                              {{ item.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p
                          id="mobile-categories-heading"
                          class="font-medium text-gray-900"
                        >
                          Education
                        </p>
                        <ul
                          role="list"
                          aria-labelledby="mobile-categories-heading"
                          class="mt-6 space-y-6"
                        >
                          <li
                            v-for="item in category.education"
                            :key="item.name"
                            class="flex"
                          >
                            <a :href="item.href" class="text-gray-500">
                              {{ item.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="grid grid-cols-1 gap-y-10 gap-x-6">
                      <div>
                        <p
                          id="mobile-collection-heading"
                          class="font-medium text-gray-900"
                        >
                          Coffee
                        </p>
                        <ul
                          role="list"
                          aria-labelledby="mobile-collection-heading"
                          class="mt-6 space-y-6"
                        >
                          <li
                            v-for="item in category.coffee"
                            :key="item.name"
                            class="flex"
                          >
                            <a :href="item.href" class="text-gray-500">
                              {{ item.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p
                          id="mobile-brand-heading"
                          class="font-medium text-gray-900"
                        >
                          Gear
                        </p>
                        <ul
                          role="list"
                          aria-labelledby="mobile-brand-heading"
                          class="mt-6 space-y-6"
                        >
                          <li
                            v-for="item in category.gear"
                            :key="item.name"
                            class="flex"
                          >
                            <a :href="item.href" class="text-gray-500">
                              {{ item.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </TransitionChild>
      </Dialog>
    </TransitionRoot>
    <header class="relative">
      <nav aria-label="Top">
        <!-- Top navigation -->
        <div class="bg-gray-900">
          <div
            class="
              max-w-7xl
              mx-auto
              h-10
              px-4
              flex
              items-center
              justify-between
              sm:px-6
              lg:px-8
            "
          >
            <!-- Currency selector -->
            <form class="hidden lg:block lg:flex-1">
              <div class="flex"></div>
            </form>
            <p
              class="
                flex-1
                text-center text-sm
                font-medium
                text-white
                lg:flex-none
              "
              v-html="promo_offer"
            ></p>
            <div
              class="
                hidden
                lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6
              "
            ></div>
          </div>
        </div>
        <!-- Secondary navigation -->
        <div class="bg-white">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="border-b border-gray-200">
              <div class="h-16 flex items-center justify-between">
                <!-- Logo (lg+) -->
                <div class="hidden lg:flex lg:items-center">
                  <a href="/">
                    <span class="sr-only">Moustache Coffee Club</span>
                    <span
                      v-html="props.logo_url_tag"
                      class="h-16 w-auto object-cover"
                    />
                  </a>
                </div>

                <div class="hidden h-full lg:flex">
                  <!-- Mega menus -->
                  <PopoverGroup class="ml-8">
                    <div class="h-full flex justify-center space-x-8">
                      <a
                        v-for="page in props.pages"
                        :key="page.name"
                        :href="page.href"
                        class="
                          flex
                          items-center
                          text-sm
                          font-medium
                          text-gray-700
                          hover:text-gray-800
                        "
                        >{{ page.name }}</a
                      >
                      <Popover
                        v-for="(category, categoryIdx) in props.categories"
                        :key="category.name"
                        v-slot="{ open }"
                        class="flex"
                      >
                        <div class="relative flex">
                          <PopoverButton
                            :class="[
                              open
                                ? 'border-violet-600 text-violet-600'
                                : 'border-transparent text-gray-700 hover:text-gray-800',
                              'relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px',
                            ]"
                          >
                            {{ category.name }}
                          </PopoverButton>
                        </div>
                        <transition
                          enter-active-class="transition ease-out duration-200"
                          enter-from-class="opacity-0"
                          enter-to-class="opacity-100"
                          leave-active-class="transition ease-in duration-150"
                          leave-from-class="opacity-100"
                          leave-to-class="opacity-0"
                        >
                          <PopoverPanel
                            class="
                              absolute
                              top-full
                              inset-x-0
                              text-gray-500
                              sm:text-sm
                              z-40
                            "
                          >
                            <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                            <div
                              class="absolute inset-0 top-1/2 bg-white shadow"
                              aria-hidden="true"
                            />
                            <div class="relative bg-white">
                              <div class="max-w-7xl mx-auto px-8">
                                <div
                                  class="
                                    grid grid-cols-2
                                    items-start
                                    gap-y-10 gap-x-8
                                    pt-10
                                    pb-12
                                  "
                                >
                                  <div
                                    class="grid grid-cols-2 gap-y-10 gap-x-8"
                                  >
                                    <div>
                                      <p
                                        :id="`desktop-featured-heading-${categoryIdx}`"
                                        class="font-medium text-gray-900"
                                      >
                                        Featured
                                      </p>
                                      <ul
                                        role="list"
                                        :aria-labelledby="`desktop-featured-heading-${categoryIdx}`"
                                        class="
                                          mt-6
                                          space-y-6
                                          sm:mt-4 sm:space-y-4
                                        "
                                      >
                                        <li
                                          v-for="item in category.featured"
                                          :key="item.name"
                                          class="flex"
                                        >
                                          <a
                                            :href="item.href"
                                            class="hover:text-gray-800"
                                          >
                                            {{ item.name }}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div>
                                      <p
                                        id="desktop-categories-heading"
                                        class="font-medium text-gray-900"
                                      >
                                        Education
                                      </p>
                                      <ul
                                        role="list"
                                        aria-labelledby="desktop-categories-heading"
                                        class="
                                          mt-6
                                          space-y-6
                                          sm:mt-4 sm:space-y-4
                                        "
                                      >
                                        <li
                                          v-for="item in category.education"
                                          :key="item.name"
                                          class="flex"
                                        >
                                          <a
                                            :href="item.href"
                                            class="hover:text-gray-800"
                                          >
                                            {{ item.name }}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    class="grid grid-cols-2 gap-y-10 gap-x-8"
                                  >
                                    <div>
                                      <p
                                        id="desktop-collection-heading"
                                        class="font-medium text-gray-900"
                                      >
                                        Coffee
                                      </p>
                                      <ul
                                        role="list"
                                        aria-labelledby="desktop-collection-heading"
                                        class="
                                          mt-6
                                          space-y-6
                                          sm:mt-4 sm:space-y-4
                                        "
                                      >
                                        <li
                                          v-for="item in category.coffee"
                                          :key="item.name"
                                          class="flex"
                                        >
                                          <a
                                            :href="item.href"
                                            class="hover:text-gray-800"
                                          >
                                            {{ item.name }}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div>
                                      <p
                                        id="desktop-brand-heading"
                                        class="font-medium text-gray-900"
                                      >
                                        Gear
                                      </p>
                                      <ul
                                        role="list"
                                        aria-labelledby="desktop-brand-heading"
                                        class="
                                          mt-6
                                          space-y-6
                                          sm:mt-4 sm:space-y-4
                                        "
                                      >
                                        <li
                                          v-for="item in category.gear"
                                          :key="item.name"
                                          class="flex"
                                        >
                                          <a
                                            :href="item.href"
                                            class="hover:text-gray-800"
                                          >
                                            {{ item.name }}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </PopoverPanel>
                        </transition>
                      </Popover>
                    </div>
                  </PopoverGroup>
                </div>
                <!-- Mobile menu and search (lg-) -->
                <div class="flex-1 flex items-center lg:hidden">
                  <button
                    type="button"
                    class="-ml-2 bg-white p-2 rounded-md text-gray-400"
                    @click="open = true"
                  >
                    <span class="sr-only">Open menu</span>
                    <MenuIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <!-- Logo (lg-) -->
                <a href="/" class="lg:hidden">
                  <span class="sr-only">Moustache Coffee Club</span>
                  <span
                    v-html="props.logo_url_tag"
                    class="h-16 w-auto object-cover"
                  />
                </a>

                <div class="flex-1 flex items-center justify-end">
                  <div class="flex items-center lg:ml-8">
                    <div class="flex space-x-8">
                      <div class="hidden lg:flex"></div>
                      <div v-if="user" class="flex">
                        <a
                          href="/accounts/schedule"
                          class="-m-2 p-2 text-gray-400 hover:text-gray-500"
                        >
                          <span class="sr-only">Schedule</span>
                          <CalendarIcon class="w-6 h-6" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                    <span
                      v-if="user"
                      class="mx-4 h-6 w-px bg-gray-200 lg:mx-6 hidden sm:block"
                      aria-hidden="true"
                    />
                    <div class="flow-root">
                      <a
                        v-if="!user"
                        href="/users/sign_in"
                        class="
                          text-sm
                          font-medium
                          text-gray-400
                          hover:text-gray-500
                        "
                        >Sign in</a
                      >
                      <a
                        v-if="user"
                        href="/users/sign_out"
                        class="
                          text-sm
                          font-medium
                          text-gray-400
                          hover:text-gray-500
                          hidden
                          md:block
                        "
                        >Sign out</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { MenuIcon, CalendarIcon, XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Dialog,
    DialogOverlay,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild,
    TransitionRoot,
    MenuIcon,

    CalendarIcon,
    XIcon,
  },
  props: {
    user: Object,
    pages: Object,
    categories: Object,
    logo_url: String,
    promo_offer: String,
    logo_url_tag: String,
  },
  setup(props) {
    const open = ref(false);

    return {
      open,
      props,
    };
  },
};
</script>
