import { createApp } from "vue";
import Carousel from "./components/Carousel.vue";
export default () => {
  document.addEventListener("DOMContentLoaded", () => {
    var element = document.getElementById("vue-carousel");

    if (element) {
      //console.log("found the vue carousel");
      //console.log(element);
      const props = { info: JSON.parse(element.getAttribute("data")) };
      //console.log("carousel props is " + JSON.stringify(props));
      if (props) {
        //console.log("mounting the carousel");
        const app = createApp(Carousel, props);
        app.mount("#vue-carousel");
      }
    }
  });
};
