import { createApp } from "vue";
import ShipmentSelect from "./components/ShipmentSelect.vue";
import axios from "axios";
import VueAxios from "vue-axios";
export default () => {
  document.addEventListener("DOMContentLoaded", () => {
    Array.prototype.forEach.call(
      document.getElementsByClassName("vue-shipment-select"),
      (element) => {
        //console.log("Element is " + element);
        if (element) {
          const props = JSON.parse(element.getAttribute("data"));
          if (!props) {
            //console.log("No props for fulfillment day");
            return;
          }

          //console.log("props is " + JSON.stringify(props));

          const app = createApp(ShipmentSelect, props);
          let token = document
            .getElementsByName("csrf-token")[0]
            .getAttribute("content");
          axios.defaults.headers.common["X-CSRF-Token"] = token;
          axios.defaults.headers.common["Accept"] = "application/json";
          app.use(VueAxios, axios);
          app.provide("axios", app.config.globalProperties.axios); // provide 'axios'
          app.mount("#" + element.id);
          //console.log("mounted shipment-select to #", element.id);
        }
      }
    );
  });
};
