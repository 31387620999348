
<template>
  <div
    class="
      flex flex-col
      rounded-lg
      shadow-lg
      overflow-hidden
      text-center
      border-t
    "
  >
    <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
      <div>
        <h3
          id="tier-standard"
          class="inline-flex mt-4 flex items-baseline text-2xl font-extrabold"
        >
          Customize Your Delivery
        </h3>
      </div>
      <div class="py-1 text-lg font-semibold tracking-wide">
        <CardSelect
          :options="[
            { id: 0, title: '12oz' },
            { id: 1, title: '6oz' },
          ]"
          label="What Bag Size?"
          selected-option="0"
          @optionSelected="updateBagSize($event)"
        />
      </div>
      <div class="py-1 text-lg font-semibold tracking-wide">
        <CardSelect
          :options="[
            { id: 0, title: 'Every Week' },
            { id: 1, title: 'Every Other Week' },
            { id: 2, title: 'Every Month' },
          ]"
          label="Delivered Every"
          selected-option="0"
          @optionSelected="updatePeriodExplainer($event)"
        />
      </div>
      <div class="mt-5 text-lg text-gray-500 mb-8">
        <div class="explainer-messages">
          <p>
            <span
              id="bag-size-explainer-message"
              v-html="bagSizeExplainerMessage"
            />
            <span
              id="period-explainer-message"
              v-html="periodExplainerMessage"
            />

            <span
              id="discount-explainer-message"
              v-html="discountExplainerMessage"
            />
          </p>
        </div>
      </div>
    </div>
    <div class="rounded-md shadow mt-8 px-6 pt-6 pb-8">
      <a
        class="
          flex
          items-center
          justify-center
          px-5
          py-3
          border border-transparent
          text-base
          font-medium
          rounded-md
          text-white
          bg-gray-800
          hover:bg-gray-900
        "
        aria-describedby="tier-standard"
        :href="'/subscriptions/join/' + planId"
        :plan-id="planId"
        rel="nofollow"
      >
        Join</a
      >
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import CardSelect from "./CardSelect.vue";

function planIDFromBagAndPeriod(bag_size, period) {
  //console.log("bagSize is " + bag_size);
  //console.log("period is " + period);
  if (bag_size == 0) {
    if (period == 0) {
      return 2305; // Duet
    }
    if (period == 1) {
      return 2304; // Solo
    }
    if (period == 2) {
      return 2306; //Lip Syncer
    }
  }

  if (bag_size == 1) {
    if (period == 0) {
      return 2182; // Variety Show
    }
    if (period == 1) {
      return 1955; // Karaoke
    }
    if (period == 2) {
      return 1012; //Whistler
    }
  }
}
function periodToWeeks(period) {
  switch (period) {
    case 0:
      return "every <b>week</b> (includes free shipping)";

    case 1:
      return "every <b>two weeks</b> (includes free shipping)";

    case 2:
      return "every <b> month </b> (includes free shipping)";
    default:
    //console.log("Error period not recognised.");
  }
}

function bagSizeToWords(bagSize) {
  switch (bagSize) {
    case 0:
      return "One <b>12oz</b> bag of amazingly fresh single-origin coffee for <b> $24 </b> ";

    case 1:
      return "One <b>6oz</b> bag of amazingly fresh single-origin coffee for <b>$14 </b> ";
    default:
    //console.log("Error bag size not recognised.");
  }
}

const bagSizeExplainerMessage = ref("bag");
const periodExplainerMessage = ref("period");
// const planId = ref(2304)
const discountExplainerMessage = ref("");

const bagSize = ref("0");
const period = ref("0");

const updateBagSize = (val) => {
  bagSize.value = val.id;

  //console.log("set the val " + val.id + " to " + bagSize.value);
  bagSizeExplainerMessage.value = bagSizeToWords(val.id);
  //console.log("output bag size " + JSON.stringify(bagSizeExplainerMessage));
};

const updatePeriodExplainer = (val) => {
  period.value = val.id;
  periodExplainerMessage.value = periodToWeeks(val.id);
  //console.log("output bag size " + JSON.stringify(periodExplainerMessage));
};
const planId = computed(() => {
  //console.log("called computed for planID");
  return planIDFromBagAndPeriod(bagSize.value, period.value);
});
updatePeriodExplainer({ id: 0 });
updateBagSize({ id: 0 });

export default {
  components: {
    CardSelect,
  },
  props: {},
  setup(props) {
    return {
      bagSize,
      period,
      bagSizeExplainerMessage,
      periodExplainerMessage,
      discountExplainerMessage,

      updateBagSize,
      updatePeriodExplainer,
      planIDFromBagAndPeriod,
      planId,
    };
  },
};
</script>
