
<template>
  <div
    class="
      flex flex-col
      rounded-lg
      shadow-lg
      overflow-hidden
      text-center
      border-t
    "
  >
    <div class="px-6 py-4 bg-white sm:p-6 sm:pb-1">
      <div>
        <h3
          id="tier-standard"
          class="inline-flex mt-4 flex items-baseline text-2xl font-extrabold"
        >
          Customize Your Gift
        </h3>
      </div>

      <div class="py-1 text-lg font-semibold tracking-wide my-12">
        <CardSelect
          :options="[
            { id: 0, title: 'One 12 oz bag', explainer: 'One 12 oz bag' },
            { id: 1, title: 'One 6 oz bag', explainer: 'One 6 oz bag' },
          ]"
          label="What Bag Size?"
          :selected-option="bagSize.id"
          @optionSelected="updateBagSize($event)"
        />
      </div>

      <div class="py-1 text-lg font-semibold tracking-wide my-12">
        <CardSelect
          :options="[
            { id: 0, title: 'Every Week', explainer: 'every week' },
            {
              id: 1,
              title: 'Every Other Week',
              explainer: 'every other week',
            },
            { id: 2, title: 'Every Month', explainer: 'every 4 weeks' },
          ]"
          label="Delivered Every"
          :selected-option="period.id"
          @optionSelected="updatePeriod($event)"
        />
      </div>

      <div class="py-1 text-lg font-semibold tracking-wide my-12">
        <CardSelect
          :options="[
            { id: 0, title: '1', explainer: ' 1 months' },
            { id: 1, title: '3', explainer: ' 3 months' },
            { id: 2, title: '6', explainer: ' 6 months' },
            { id: 3, title: '12', explainer: ' 12 months' },
          ]"
          label="For how many months?"
          :selected-option="months.id"
          @optionSelected="updateNumberOfMonths($event)"
        />
      </div>

      <div class="mt-5 text-lg font-bold text-gray-400 mb-8">
        <div class="explainer-messages">
          <p>
            <span>
              {{ bagSize.explainer }}
            </span>
            delivered
            <span>
              {{ period.explainer }}
            </span>

            for

            <span>
              {{ months.explainer }}
            </span>
            for a total of
            <span class="text-gray-500">
              {{ totalPrice }}
            </span>
          </p>
        </div>
      </div>
      <div class="sm:grid-cols-2">
        <div class="sm:col-span-1">
          <div class="w-full md:w-64">
            <label
              for="email"
              class="block text-sm font-medium text-gray-700 sm:mt-4 sm:mb-4"
              >Email address of the recipient</label
            >

            <div flex inline>
              <input
                id="email"
                v-model="email"
                type="text"
                name="remail"
                class="
                  flex
                  mt-1
                  focus:ring-violet-500 focus:border-violet-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  sm:mt-4
                "
                :class="{
                  'ring-red-500': msg['email'],
                  'border-red-500': msg['email'],
                }"
                @blur="validateEmail"
              />
              <label
                v-if="msg['email']"
                for="email"
                class="
                  flex
                  inline
                  text-sm
                  font-medium
                  sm:mt-4 sm:mb-4
                  text-red-500
                "
              >
                {{ msg["email"] }}</label
              >
            </div>
          </div>
          <div>
            <div class="w-full md:w-64">
              <label
                for="deliveryOn"
                class="block text-sm font-medium text-gray-700 sm:mt-4 sm:mb-4"
                >Deliver on:</label
              >

              <input
                id="deliveryOn"
                v-model="deliveryOn"
                type="date"
                name="deliveryOn"
                min="new Date().toISOString().slice(0, 10)"
                class="
                  mt-1
                  focus:ring-violet-500 focus:border-violet-500
                  text-gray-700
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  sm:mt-4
                "
              />
            </div>
          </div>
        </div>
        <div class="sm:col-span-1">
          <div>
            <label
              for="message"
              class="block text-sm font-medium text-gray-700 sm:mt-4 sm:mb-4"
            >
              An (optional) message which we will send in the gift email
            </label>
            <div class="mt-1">
              <textarea
                id="message"
                v-model="message"
                rows="4"
                name="message"
                class="
                  shadow-sm
                  focus:ring-violet-500 focus:border-violet-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="py-1 text-lg font-semibold tracking-wide my-6"
        id="calculate_price"
      >
        Total Price: {{ totalPrice }}
      </div>
    </div>

    <div v-if="user && user.registered_card">
      <div class="py-1 text-md font-medium tracking-wide my-6">
        Using card: {{ user.active_card_last_4 }}
        <a :href="updateCardUrl" class="text-gray-400 font-light"> (Change) </a>
      </div>

      <div class="rounded-md shadow px-6 pt-2 pb-8">
        <a
          class="
            flex
            items-center
            justify-center
            px-5
            py-3
            border border-transparent
            text-base
            font-medium
            rounded-md
            text-white
            bg-gray-800
            hover:bg-gray-900
          "
          aria-describedby="tier-standard"
          rel="nofollow"
          @click="submitButton"
          :disabled="isLoading"
        >
          Purchase</a
        >
      </div>
    </div>
    <div v-else>
      <div v-if="user && user.email">
        <div class="py-1 text-md font-medium tracking-wide my-6">
          <a :href="updateCardUrl" class="text-gray-400 font-light">
            <div class="rounded-md shadow px-6 pt-2 pb-8">
              <a
                class="
                  flex
                  items-center
                  justify-center
                  px-5
                  py-3
                  border border-transparent
                  text-base
                  font-medium
                  rounded-md
                  text-white
                  bg-gray-800
                  hover:bg-gray-900
                "
                aria-describedby="tier-standard"
                rel="nofollow"
              >
                Add a Card to Purchase</a
              >
            </div>
          </a>
        </div>
      </div>

      <div v-else>
        <div class="py-1 text-md font-medium tracking-wide my-6">
          <a :href="newUserUrl" class="text-gray-400 font-light">
            <div class="rounded-md shadow px-6 pt-2 pb-8">
              <a
                class="
                  flex
                  items-center
                  justify-center
                  px-5
                  py-3
                  border border-transparent
                  text-base
                  font-medium
                  rounded-md
                  text-white
                  bg-gray-800
                  hover:bg-gray-900
                "
                aria-describedby="tier-standard"
                rel="nofollow"
              >
                Sign up to Purchase</a
              >
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, inject, reactive } from "vue";
import CardSelect from "./CardSelect.vue";
import { showErrorMessage } from "../utils/errorProcessing.js";

import { useCookies } from "vue3-cookies";

export default {
  components: {
    CardSelect,
  },
  props: {
    calc_price_url: String,
    create_gift_url: String,
    update_card_url: String,
    new_user_url: String,
    user: Object,
  },
  setup(props) {
    const { cookies } = useCookies();
    window.cmax = cookies;
    const isLoading = ref(false);
    const msg = reactive({});
    const message = ref(cookies.get("message") ? cookies.get("message") : "");
    const email = ref(cookies.get("email") ? cookies.get("email") : "");
    const user = props.user;
    console.log(JSON.stringify(user));
    const axios = inject("axios"); // inject axios
    const updateCardUrl = props.update_card_url;
    const newUserUrl = props.new_user_url;
    const deliveryOn = ref(
      cookies.get("deliveryOn")
        ? cookies.get("deliveryOn")
        : new Date().toISOString().slice(0, 10)
    );
    const period = ref(
      cookies.get("period")
        ? cookies.get("period")
        : {
            id: 1,
            title: "Every Other Week",
            explainer: "every other week",
          }
    );
    const bagSize = ref(
      cookies.get("bagSize")
        ? cookies.get("bagSize")
        : {
            id: 0,
            title: "One 12 oz bag",
            explainer: "One 12 oz bag",
          }
    );
    const months = ref(
      cookies.get("months")
        ? cookies.get("months")
        : { id: 0, title: '1', explainer: ' 1 months' }
      );

    const totalPrice = ref("");

    const calcPlanId = () => {
      //console.log("the period.value.id = ", JSON.stringify(period.value.id));
      //console.log("the bagSize.value.title = ", bagSize.value.title);
      switch (period.value.id) {
        case 0:
          //console.log("Every week");
          if (bagSize.value.title == "One 12 oz bag") {
            //console.log("returning DUET----");
            return plans["Duet"];
          } else {
            // it's a 6 oz
            return plans["Variety Show"];
          }

        case 1:
          // "Every Other Week"
          if (bagSize.value.title == "One 12 oz bag") {
            return plans["Solo Artist"];
          } else {
            // it's a 6 oz
            return plans["Karaoke"];
          }
        case 2:
          if (bagSize.value.title == "One 12 oz bag") {
            return plans["Lip Syncer"];
          } else {
            // it's a 6 oz
            return plans["Whistler"];
          }
        default:
        //console.log("Error period not recognized");
      }
    };

    const plans = {
      Duet: 2305,
      "Solo Artist": 1710,
      "Lip Syncer": 1038,
      Karaoke: 1955,
      Whistler: 1012,
      "Variety Show": 2182,
    };

    const updateBagSize = (ev) => {
      //console.log("update bag size", ev);
      bagSize.value = ev;
      //console.log("bag size is ", bagSize.value);
    };
    const updatePeriod = (ev) => {
      //console.log("update Period", ev);
      period.value = ev;

      //console.log("period is ", period);
    };

    const updateNumberOfMonths = (ev) => {
      //console.log("update Num Months", ev);
      months.value = ev;

      //console.log("months is ", months);
    };

    const updatePrice = () => {
      let plan_id = calcPlanId();
      //console.log("plan id is ", plan_id);
      axios
        .put(props.calc_price_url, {
          calculate_price_plan_id: plan_id,
          calculate_price_months: months.value.title,
        })
        .then((result) => {
          //console.log(result);
          //console.log("result is ", result.data.price);
          totalPrice.value = result.data.price;
          //console.log("totalPrice ==  ", totalPrice.value);
        })
        .catch((err) => console.log(err));
    };

    watchEffect(() => {
      updatePrice();
    });
    watchEffect(() => {
      cookies.set("bagSize", bagSize.value);
    });

    watchEffect(() => {
      cookies.set("period", period.value);
    });

    watchEffect(() => {
      //console.log("watchEffect kicking off");
      cookies.set("months", months.value);
    });

    watchEffect(() => {
      cookies.set("message", message.value);
    });

    watchEffect(() => {
      cookies.set("email", email.value);
    });

    watchEffect(() => {
      cookies.set("deliveryOn", deliveryOn.value);
    });

    updatePrice();
    msg["email"] = false;
    const validateEmail = () => {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
        msg["email"] = "Please enter a valid email address";

        return false;
      } else {
        msg["email"] = false;
        return true;
      }
    };
    
    const submitButton = () => {
      if (isLoading.value) {
        console.log("Already submitted ... waiting for response");
        return;
      }
      isLoading.value = true;

      setTimeout(() => {
        console.log("resetting isLoading");
        isLoading.value = false;
      }, 3000);

      if (!validateEmail()) {
        document.getElementById("email").scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
        return;
      }

      axios
        .post(props.create_gift_url, {
          gift: {
            plan_id: calcPlanId(),
            months: months.value.title,
            delivery_on: deliveryOn.value,
            email: email.value,
            message: message.value,
          },
        })
        .then((response) => {
          //console.log(response);

          window.location = response.data.redirect_url;
        })
        .catch((err) => {
          // console.log(JSON.stringify(err));

          showErrorMessage(err.message);
          isLoading.value = false;
        });
    };

    return {
      period,
      bagSize,
      updateBagSize,
      updatePeriod,
      updateNumberOfMonths,
      months,
      totalPrice,
      message,
      email,
      user,
      updateCardUrl,
      newUserUrl,
      submitButton,
      validateEmail,
      msg,
      deliveryOn,
      isLoading,
    };
  },
};
</script>
