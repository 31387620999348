<template>
  <div class="bg-white">
    <div class="m-10 pb-10 border-gray-200">
      <Carousel
        autoplay="5000"
        wrap-around="true"
        pause-autoplay-on-hover="true"
        transition="600"
      >
        <Slide v-for="slide in props.info" :key="slide.id">
          <div v-html="slide" />
        </Slide>

        <template #addons>
          <Navigation />

          <Pagination class="mt-6" />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: { info: Object },

  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style>
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  color: grey;
  background-color: white;
}
</style>
