<template>
  <div class="mx-4">
    <CardSelect
      :options="options"
      label=""
      :selected-option="selectedOption"
      @optionSelected="setBagSize"
    />

    <div v-if="!props.sold_out" class="mt-4 mb-4 w-full">
      <button
        type="button"
        class="
          items-center
          px-6
          py-3
          border border-transparent
          text-base
          font-medium
          rounded-md
          shadow-sm
          text-white
          bg-violet-600
          hover:bg-violet-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-violet-500
          w-full
        "
        @click="submitButton"
      >
        Reserve For Shipment
      </button>
    </div>
    <div v-else class="mt-4 mb-4 w-full">
      <button
        type="button"
        disabled
        class="
          items-center
          px-6
          py-3
          border border-transparent
          text-base
          font-medium
          rounded-md
          shadow-sm
          text-white
          bg-violet-600
          focus:outline-none
          opacity-50
          w-full
        "
      >
        Sold Out
      </button>
    </div>
  </div>
</template>
<script>
import { inject } from "vue";
import CardSelect from "./CardSelect.vue";
import { showErrorMessage } from "../utils/errorProcessing.js";

export default {
  components: {
    CardSelect,
  },
  props: {
    data: Object,
    url: String,
    initial: String,
    fd_id: String,
    sold_out: Boolean
  },
  setup(props) {
    const options = props.data.map((p) => {
      return { id: p.id, title: p.name };
    });
    let selectedBagSize = props.initial;
    let selectedOption = props.initial;
    const url = props.url;
    const submitButton = () => {
      axios
        .put(url, { sku: selectedBagSize })
        .then(() => {
          window.location = "/accounts/schedule#fulfillment_day-" + props.fd_id;
        })
        .catch((response) => showErrorMessage(response));
    };
    const axios = inject("axios"); // inject axios

    const setBagSize = (payload) => {
      selectedBagSize = payload.id;
      //console.log("set the selectedBagSize to ", selectedBagSize);
    };

    //console.log("the props are ", props);

    //console.log("the options are ", options);
    return {
      options,
      setBagSize,
      submitButton,
      selectedOption,
      props,
    };
  },
};
</script>
