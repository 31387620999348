<template>
  <div>
    <div
      class="
        mt-2
        mb-4
        block
        text-lg
        leading-8
        tracking-tight
        font-medium
        text-gray-900
        sm:text-2xl
      "
    >
      Tell Us What You Thought
    </div>
    <div class="inline-block">
      <div class="inline-block">
        <VueStarRating
          class="mb-6 inline-block w-56"
          :star-size="30"
          :increment="0.5"
          text-class="custom-text"
          :rating="props.user_rating / 2"
          @update:rating="submitRating"
        ></VueStarRating>
      </div>
      <div class="inline-block text:sm text-gray-500">
        {{ message }}
      </div>
    </div>
    <form class="space-y-8" action="/comments" method="POST">
      <input type="hidden" name="authenticity_token" :value="CSRFToken" />
      <input type="hidden" name="item_id" :value="props.item.id" />
      <div class="space-y-8 sm:space-y-5">
        <span class="mt-2 mb-4 block text-sm text-gray-500 sm:text-md">
          Written feedback is optional.
        </span>
        <div>
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                id="review"
                name="title"
                rows="1"
                class="
                  max-w-lg
                  shadow-sm
                  block
                  w-full
                  focus:ring-violet-500 focus:border-violet-500
                  sm:text-sm
                  border border-gray-300
                  rounded-md
                "
              />
              <p class="mt-2 text-sm text-gray-500">Title</p>
            </div>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                id="review"
                name="body"
                rows="3"
                class="
                  max-w-lg
                  shadow-sm
                  block
                  w-full
                  focus:ring-violet-500 focus:border-violet-500
                  sm:text-sm
                  border border-gray-300
                  rounded-md
                "
                placeholder="What flavor notes did you taste?"
              />
              <p class="mt-2 text-sm text-gray-500">Body</p>
            </div>
          </div>
          <div class="pt-5">
            <div class="flex justify-start">
              <button
                type="submit"
                class="
                  inline-flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-violet-600
                  hover:bg-violet-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-violet-500
                "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
  <script>
import { inject, ref } from "vue";
import VueStarRating from "vue-star-rating";

export default {
  components: { VueStarRating },
  props: { item: Object, user_rating: Number },
  setup(props) {
    const CSRFToken = document
      .getElementsByName("csrf-token")[0]
      .getAttribute("content");

    const axios = inject("axios"); // inject axios
    let message = ref("");
    const submitRating = (event) => {
      message.value = "";
      //console.log("-------- Firing -------");
      //console.log(event);

      axios
        .post(props.item.rate_url, { value: event * 2 })
        .then((res) => {
          //console.log(res);
          message.value = "saved";
        })
        .catch((err) => {
          //console.log(err);
          message.value = "error";
        });
    };

    return { props, submitRating, message, CSRFToken };
  },
};
</script>
<style >
.custom-text {
  font-weight: bold;
  font-size: 1.2em;
  border: 1px solid #cfcfcf;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: #999;
  background: #fff;
}
</style>