import axios from "axios";
import VueAxios from "vue-axios";
import { createApp } from "vue";
import StarRating from "./components/StarRating.vue";
export default () => {
  document.addEventListener("DOMContentLoaded", () => {
    Array.prototype.forEach.call(
      document.getElementsByClassName("vue-star-rating"),
      (element) => {
        //console.log("Element is " + element);
        if (element) {
          const props = JSON.parse(element.getAttribute("data"));
          if (!props) {
            //console.log("No props star rating");
            return;
          }
          const app = createApp(StarRating, props);
          let token = document
            .getElementsByName("csrf-token")[0]
            .getAttribute("content");
          axios.defaults.headers.common["X-CSRF-Token"] = token;
          axios.defaults.headers.common["Accept"] = "application/json";
          app.use(VueAxios, axios);
          app.provide("axios", app.config.globalProperties.axios); // provide 'axios'
          app.mount("#" + element.id);
          //console.log("mounted star-rating");
        } else {
          //console.log("somehow element is false : ", element);
        }
      }
    );
  });
};
